// ** Initial State
const initialState = {
  allData: [],
  data: [],
  Contacts: [],
  Countries: [],
  ContactsGroups: [],
  total: 1,
  params: {},
  error: null,
  errorWebSite: null,
};
const ContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CONTACT":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
      };
    case "GET_ALL_CONTACT":
      return {
        ...state,
        Contacts: action.Contacts,
        ContactsGroups: action.ContactsGroups,
      };
    case "GET_ALL_COUNTRY":
      return {
        ...state,
        Countries: action.Countries,
      };
    case "EMPTY_ERROR":
    case "ADD_CONTACT_SUCCESS":
    case "EDIT_CONTACT_SUCCESS":
      return { ...state, error: null };
    case "ADD_CONTACT_FAIL":
    case "EDIT_CONTACT_FAIL":
      return { ...state, error: action.payload };
    case "EDIT_CONTACT_WEBSITE_SUCCESS":
      return { ...state, errorWebSite: null };
    case "EDIT_CONTACT_WEBSITE_FAIL":
      return { ...state, errorWebSite: action.payload };
    default:
      return { ...state };
  }
};

export default ContactReducer;
