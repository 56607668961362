import mock from "../mock";

export const searchArr = [
  {
    groupTitle: "Pages",
    searchLimit: 4,
    data: [
      {
        id: 1,
        target: "dashboard",
        isBookmarked: false,
        title: "Dashboard",
        icon: "Home",
        link: "/admin/dashboard",
      },
      {
        id: 2,
        target: "statistics",
        isBookmarked: false,
        title: "Statistics by date",
        icon: "BarChart2",
        link: "/admin/date/statistics",
      },
      {
        id: 5,
        target: "calendar",
        isBookmarked: false,
        title: "Calendar",
        icon: "Calendar",
        link: "/admin/calendar",
      },
      {
        id: 11,
        target: "Podcast",
        isBookmarked: false,
        title: "Podcasts",
        icon: "Mic",
        link: "/admin/podcasts",
      },
      {
        id: 12,
        target: "rush",
        isBookmarked: false,
        title: "Rush",
        icon: "List",
        link: "/admin/rushs",
      },
      {
        id: 13,
        target: "Emission",
        isBookmarked: false,
        title: "Emissions",
        icon: "Mic",
        link: "/admin/planning/emissions",
      },
      {
        id: 15,
        target: "Songs",
        isBookmarked: false,
        title: "Songs",
        icon: "Music",
        link: "/admin/multimedia/songs",
      },
      {
        id: 16,
        target: "Albums",
        isBookmarked: false,
        title: "Albums",
        icon: "Image",
        link: "/admin/multimedia/albums",
      },
      {
        id: 17,
        target: "artistes",
        isBookmarked: false,
        title: "Artistes",
        icon: "User",
        link: "/admin/multimedia/artistes",
      },
      {
        id: 18,
        target: "jingle",
        isBookmarked: false,
        title: "Jingles",
        icon: "Play",
        link: "/admin/multimedia/jingles",
      },
      {
        id: 19,
        target: "video",
        isBookmarked: false,
        title: "Videos",
        icon: "Video",
        link: "/admin/videos",
      },
      {
        id: 20,
        target: "credit",
        isBookmarked: false,
        title: "Credits",
        icon: "Airplay",
        link: "/admin/multimedia/credits",
      },
      {
        id: 21,
        target: "publicities",
        isBookmarked: false,
        title: "Publicities",
        icon: "Mic",
        link: "/admin/multimedia/publicities",
      },
      {
        id: 22,
        target: "playlist",
        isBookmarked: false,
        title: "Playlists",
        icon: "Play",
        link: "/admin/multimedia/playlists",
      },
      {
        id: 23,
        target: "Multimedia categories",
        isBookmarked: false,
        title: "Multimedia Categories",
        icon: "List",
        link: "/admin/categories/media",
      },
      {
        id: 25,
        target: "live Waiting Room",
        isBookmarked: false,
        title: "Live Waiting Room",
        icon: "Mic",
        link: "/admin/live/waiting_room",
      },
      {
        id: 26,
        target: "Rush Waiting Room",
        isBookmarked: false,
        title: "Rush Waiting Room",
        icon: "Mic",
        link: "/admin/rush/waiting_room",
      },
      {
        id: 27,
        target: "live studio",
        isBookmarked: false,
        title: "Live Studio",
        icon: "Radio",
        link: "/admin/studio/live",
      },
      {
        id: 41,
        target: "userslist",
        isBookmarked: false,
        title: "Users List",
        icon: "Users",
        link: "/admin/users/list",
      },
      {
        id: 42,
        target: "Roles",
        isBookmarked: false,
        title: "Roles",
        icon: "Database",
        link: "/admin/roles",
      },
      {
        id: 43,
        target: "permissions",
        isBookmarked: false,
        title: "Permissions",
        icon: "Globe",
        link: "/admin/permission",
      },
      {
        id: 44,
        target: "chat",
        isBookmarked: false,
        title: "Chat",
        icon: "MessageSquare",
        link: "/admin/chat/private",
      },
      {
        id: 45,
        target: "episodes",
        isBookmarked: false,
        title: "Episodes",
        icon: "Headphones",
        link: "/admin/planning/episodes",
      },
      {
        id: 46,
        target: "category",
        isBookmarked: false,
        title: "Category",
        icon: "Type",
        link: "/admin/planning/category",
      },
      {
        id: 47,
        target: "classification",
        isBookmarked: false,
        title: "Classification",
        icon: "Filter",
        link: "/admin/planning/classification",
      },
      {
        id: 48,
        target: "language",
        isBookmarked: false,
        title: "Language",
        icon: "Globe",
        link: "/admin/planning/language",
      },
      {
        id: 49,
        target: "tag",
        isBookmarked: false,
        title: "Tag",
        icon: "Tag",
        link: "/admin/planning/tag",
      },
      {
        id: 50,
        target: "default_playlist",
        isBookmarked: false,
        title: "Default playlist",
        icon: "Play",
        link: "/admin/default_playlist",
      },

      {
        id: 51,
        target: "Services",
        isBookmarked: false,
        title: "Services",
        icon: "FilePlus",
        link: "/admin/Services/topics",
      },
      {
        id: 52,
        target: "News",
        isBookmarked: false,
        title: "News",
        icon: "FilePlus",
        link: "/admin/News/topics",
      },
      {
        id: 53,
        target: "Blog",
        isBookmarked: false,
        title: "Blog",
        icon: "FilePlus",
        link: "/admin/Blog/topics",
      },
      {
        id: 55,
        target: "Photos",
        isBookmarked: false,
        title: "Photos",
        icon: "Image",
        link: "/admin/Photos/topics",
      },
      {
        id: 56,
        target: "Products",
        isBookmarked: false,
        title: "Products",
        icon: "Layers",
        link: "/admin/Products/topics",
      },
      {
        id: 57,
        target: "Partners",
        isBookmarked: false,
        title: "Partners",
        icon: "Key",
        link: "/admin/Partners/topics",
      },
      {
        id: 59,
        target: "frontoffice_template",
        isBookmarked: false,
        title: "Frontoffice template",
        icon: "Settings",
        link: "/admin/WebsiteSettings/frontoffice_template",
      },
      {
        id: 60,
        target: "monotoring",
        isBookmarked: false,
        title: "Monotoring",
        icon: "PieChart",
        link: "/admin/streamMonitor/stats",
      },
      {
        id: 60,
        target: "tags_media",
        isBookmarked: false,
        title: "Tags media",
        icon: "List",
        link: "/admin/tags/media",
      },
      {
        id: 61,
        target: "chat",
        isBookmarked: false,
        title: "Public chat",
        icon: "MessageSquare",
        link: "/admin/chat/public",
      },
    ],
  },
];

// GET Search Data
mock.onGet("/api/main-search/data").reply((config) => {
  return [200, { searchArr }];
});

// GET Search Data & Bookmarks
mock.onGet("/api/bookmarks/data").reply((config) => {
  const bookmarks = searchArr[0].data.filter((item) => item.isBookmarked);
  const suggestions = searchArr[0].data;
  return [200, { suggestions, bookmarks }];
});

// POST Update isBookmarked
mock.onPost("/api/bookmarks/update").reply((config) => {
  const { id } = JSON.parse(config.data);

  const obj = searchArr[0].data.find((item) => item.id === id);

  Object.assign(obj, { isBookmarked: !obj.isBookmarked });

  return [200];
});
