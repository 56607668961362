// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedEpisodes: null,
  episodesTypes: null,
  error: null,
  next: null,
  episodesByEmission: [],
  extendMsg: "",
  softDeletedEPisodes: [],
  data_waiting_room: [],
  totalLives: 0,
  episodesByCategory: [],
  episodesByTag: [],
  episode_by_type: [],
  episodesByClassification: [],
  episode_by_type_total: 0,
  episodeLastPage: 0,
  draft_episode: [],
  upload_success: -1,
  loading: false,
};

const episodes = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_EPISODES":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        ep: action.data,
      };
    case "GET_All_EPISODES_BY_TYPEID":
      return {
        ...state,
        episode_by_type: action.data,
        episode_by_type_total: action.total,
        params: action.params,
      };
    case "GET_EPISODES_EMISSION_ID":
      return {
        ...state,
        episodesByEmission: action.data,
        total: action.total,
        params: action.params,
      };
    case "GET_EPISODES_BY_WEEK":
      return {
        ...state,
        data: action.data,
      };
    case "GET_EPISODE":
      return { ...state, selectedEpisodes: action.selectedEpisodes };
    case "GET_DRAFT_EPISODE_ID":
      return { ...state, draft_episode: action.data };
    case "GET_NEXT_EPISODE":
      return { ...state, next: action.next };
    case "UPLOAD_SUCCESS":
      return { ...state, upload_success: action.payload };
    case "GET_LIVE_STUDIO":
      return {
        ...state,
        data_waiting_room: action.data,
        totalLives: action.totalLives,
      };
    case "EXTEND_EPISODE_SUCCESS":
    case "EXTEND_EPISODE_FAIL":
      return { ...state, extendMsg: action.payload };
    case "FETCH_DATA_LOADING":
      return { ...state, loading: true, error: null };
    case "EMPTY_ERROR":
    case "ADD_EPISODE_SUCCESS":
    case "EDIT_EPISODE_SUCCESS":
      return { ...state, loading: false, error: null };
    case "ADD_EPISODE_FAIL":
    case "EDIT_EPISODE_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "DELETE_EPISODE":
      return { ...state };
    case "GET_ALL_EPISODES_TYPES":
      return { ...state, episodesTypes: action.episodesTypes };
    case "SOFT_DELETED_EPISODES":
      return { ...state, softDeletedEPisodes: action.payload };
    case "GET_EPISODES_BY_CATEGORY_ID":
      return { ...state, episodesByCategory: action.data };
    case "GET_EPISODES_BY_CATEGORY_SLUG_LASTPAGE":
      return { ...state, episodeLastPage: action.lastPage };
    case "GET_EPISODES_BY_TAG_ID":
      return { ...state, episodesByTag: action.data };
    case "GET_EPISODES_BY_CLASSIFICATION_ID":
      return { ...state, episodesByClassification: action.data };

    case "GET_EPISODES_BY_TAG_SLUG_LASTPAGE":
      return { ...state, episodeLastPage: action.lastPage };
    default:
      return { ...state };
  }
};
export default episodes;
