// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  deletedTickets: [],
  priority: [],
  status: [],
  comments: [],
  error: null,
  errorStatus: null,
  errorPriority: null,
};
const TicketReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_TICKETS":
      return {
        ...state,
        data: action.data,
        params: action.params,
        total: action.totalPages,
      };
    case "GET_ALL_DELETED_TICKETS":
      return {
        ...state,
        deletedTickets: action.data,
        params: action.params,
        total: action.totalPages,
      };
    case "GET_ALL_STATUS":
      return {
        ...state,
        status: action.data,
      };
    case "GET_ALL_PRIORITY":
      return {
        ...state,
        priority: action.data,
      };
    case "GET_TICKET_ID":
      return {
        ...state,
        single: action.data,
      };
    case "GET_COMMENTS_BY_TICKET_ID":
      return {
        ...state,
        comments: action.data,
      };
    case "EMPTY_ERROR":
    case "ADD_TICKETS_SUCCESS":
    case "ADD_COMMENTS_TICKETS_SUCCESS":
    case "EDIT_TICKETS_SUCCESS":
    case "RESTORE_TICKETS_SUCCESS":
    case "EDIT_TICKET_STATUS_SUCCESS":
      return { ...state, error: null };
    case "ADD_TICKETS_FAIL":
    case "ADD_COMMENTS_TICKETS_FAIL":
    case "EDIT_TICKETS_FAIL":
    case "RESTORE_TICKETS_FAIL":
    case "EDIT_TICKET_STATUS_FAIL":
      return { ...state, error: action.payload };
    case "ADD_TICKET_STATUS_FAIL":
      return { ...state, errorStatus: action.payload };
    case "ADD_TICKET_PRIORITY_FAIL":
      return { ...state, errorPriority: action.payload };
    default:
      return { ...state };
  }
};

export default TicketReducer;
